import React, { useEffect } from 'react';
import { initOAuth } from "../../utils/scripts/auth";
import Config from "../../utils/Config";

const OAuthInit = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const clientId = searchParams.get('client_id') || Config.CLIENT_ID;
  const merchantId = searchParams.get('merchant_id') || Config.MERCHANT_ID;

  useEffect(() => {
    if (clientId) {
      initOAuth(clientId, merchantId);
    }
  }, [clientId, merchantId]);

  return <></>;
};

export default OAuthInit;
