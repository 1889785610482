import { CLOVER_OAUTH_PARAMS } from "../shared/consts/CloverOAuthParams.consts";
import Config from "../utils/Config";

const CLOVER_PATH = {
  OAUTH: (clientId: string, merchantId: string | null = null) =>
    `${Config.CLOVER_BASE_URL}/oauth/v2/authorize?${CLOVER_OAUTH_PARAMS.CLIENT_ID}=${clientId}${
      merchantId ? `&${CLOVER_OAUTH_PARAMS.MERCHANT_ID}=${merchantId}` : ''
    }`
};

export { CLOVER_PATH };
