import {Box} from '@mui/material';
import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import Config from "../../utils/Config";

const Header = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: 1,
                borderColor: 'divider',
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                backgroundColor: '#FF9700',
                zIndex: 10,
            }}>
                <a href={Config.CLOVER_HOME_URL} style={{marginLeft: 10, padding: 5}}><HomeIcon htmlColor='#ffffff'/></a>
                <h3 style={{textAlign: "left", background: '#FF9700', margin: 0, padding: 15, color: "white"}}>Cash
                    Discount Program: Reports</h3>
            </Box>
        </>
    );
}

export default Header;
