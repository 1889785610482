import * as React from 'react';
import Box from '@mui/material/Box';
import {List, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography/Typography";

const OrderHeader: React.FC<{ orderId: any }> = (props) => {
    return (
        <>
            <Box className={'order-title'}>
                {/*Your Order: {props.orderId != undefined ? props.orderId.id : 'none'}*/}
                Your Order
            </Box>
            <List
                component="ul"
                sx={{
                    py: 0,
                    height: 'auto',

                }}
            >
                <ListItem className={'order-lineitem-wrapper'} divider>
                    <List
                        component="ul"
                        sx={{
                            py: 0,
                            width: '100%'
                        }}
                    >
                        <ListItem className={'order-lineitem-wrapper'}>
                            <Typography className={'order-title-big'} variant="subtitle1"> </Typography>
                            <Typography className={'order-title-small order-title-count'} variant="subtitle1"></Typography>
                            <Typography className={'wrap-amount'}>
                                <Typography className={'order-title-big head'} variant="subtitle1">Cash Price</Typography>
                                <Typography className={'order-title-big head'} variant="subtitle1">Credit Price</Typography>
                            </Typography>
                        </ListItem>
                    </List>
                </ListItem>
            </List>
        </>

    );
}
export default OrderHeader;
