export default {
    BASE_URL: process.env.REACT_APP_BASE_URL!!,
    BASE_PATH: process.env.REACT_APP_BASE_PATH!!,
    CLOVER_HOME_URL: process.env.REACT_APP_CLOVER_HOME_URL!!,
    CLOVER_BASE_URL: process.env.REACT_APP_CLOVER_BASE_URL!!,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID!!,
    MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID!!,
    AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID!!,
    AWS_SECRET_KEY: "LV9TgQ/ur5Q2ikhFzqxz/Xo855EA/MjAKPqMfgya",
    ENV_NAME: process.env.REACT_APP_ENV_NAME!!,
};